import { useMutation } from "react-apollo";
import { useState } from "react";
import { REMOVE_BLOCKED_DAY } from "../Queries/reservations";
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';

const UseRemoveBlockedDay = (client,reservationPersonID,OnSuccess) => {

    const [showRemoveNotify, SetShowRemoveNotify] = useState(false);
    const [selectedData, SetSelectedData] = useState(null);

    const [RemBlockDay,{error,loading}] = useMutation(REMOVE_BLOCKED_DAY,{
        onCompleted:(d) => {

            OnSuccess(d.reservationBlockDay);
            
            const notify = new Notification();
            notify.setNotification(null,"A je to fuč.",true,false,client);

        },
        onError:(err) => {
            const helper = new Helper();
            const notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,client);   
        }
    });

    const ShowRemoveNotification = (date,customer) => {
        SetSelectedData({date,customer});
        SetShowRemoveNotify(true);
    }

    const RemoveBlockDay = (act) => {

        if(act)
        {
            RemBlockDay({
                variables:{
                    date: selectedData.date,
                    isReservation: selectedData.customer ? true : false,
                    reservationPersonID
                }
            })
        }
        SetSelectedData(null);
        SetShowRemoveNotify(false);
    }

    return {
        showRemoveNotify,
        ShowRemoveNotification,
        RemoveBlockDay
    }
}

export default UseRemoveBlockedDay;