import Error from "../../../GlobalComponents/Error";
import AddEditModalHeader from "../../../GlobalComponents/AddEditModalHeader";
import UseAddEditReview from "../Hooks/UseAddEditReview";
import UseGetReview from "../Hooks/UseGetReview";
import Modal from 'react-modal';
import Loading from "../../../GlobalComponents/Loading";
import { withApollo } from "react-apollo";
import ChoosePhoto from "../../../GlobalComponents/ChoosePhoto";
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';

const AddEditReview = ({selectedReviewID,SetSelectedReviewID,client,UpdateAfterAddEdit}) => {

    const {
        error,
        loading,
        formData,
        AddEditReview,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    } = UseAddEditReview(selectedReviewID,client,(d) => OnAddEditSuccess(d));

    const {allLanguageMutations, loading:getLoading,error:getError} = UseGetReview(selectedReviewID,(d) => {
        InitFormData({
            active: d.active,
            photo:  d.photo,
            langs: d.langs,
            mainPhoto: d.mainPhoto,
            selectedLang: d.mainLanguage
        })
    });

    const OnAddEditSuccess = (d) => {
        SetSelectedReviewID(null);
        UpdateAfterAddEdit(d,(selectedReviewID == 0 ? "add" : "edit"));
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => SetSelectedReviewID(null)}
        >
            <div className="modal-content">

                <AddEditModalHeader
                    allLanguageMutations = {allLanguageMutations}
                    title = {(selectedReviewID && selectedReviewID != 0 ? "Úprava recenze" : "Přidání recenze")}
                    selectedLang = {formData.selectedLang}
                    SelectLanguage = {SetFormData}
                    Close = {() => SetSelectedReviewID(null)}
                />
                
                <div className="modal-body">

                    {error ?
                        <Error text={error || getError} />
                    :
                    <div>
                        
                        {loading || getLoading ?
                            <Loading /> 
                        :                             
                            <div className="row">
                                <div className="col-12 col-sm-6">

                                    <ChoosePhoto
                                        photoUrl = {formData.mainPhoto}
                                        SetPhotoData = {(photo) => SetFormData("photo",photo)}
                                    />
                                    
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="input-label">Aktivní</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                <label className="form-check-label" htmlFor="active1">
                                                    Ano
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                <label className="form-check-label" htmlFor="active2">
                                                    Ne
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="input-label">Text recenze ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="text"
                                                value={GetFormLangValue("text")}
                                                OnEditorChange={(content) => SetFormLangData("text",content)}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="input-label">Podpis u recenze ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="signature"
                                                value={GetFormLangValue("signature")}
                                                OnEditorChange={(content) => SetFormLangData("signature",content)}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }  

                    </div>
                    }

                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => AddEditReview()}>{(selectedReviewID && selectedReviewID != 0 ? "Uložit" : "Přidat")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => SetSelectedReviewID(null)}>Zavřít</button>
                </div>

            </div>
        </Modal>
    );
}

export default withApollo(AddEditReview);