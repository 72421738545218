import { useState } from "react";
import { DELETE_REVIEWS } from "../Queries/review";
import { useMutation } from "react-apollo";
import Notification from '../../../Library/notification';
import Helper from "../../../Library/helper";
import UseSelectItemToDelete from "../../../GlobalHooks/UseSelectItemToDelete";

const UseDeleteReviews = (client,OnCompleted) => {

    const [selectedItems,SelectItem] = UseSelectItemToDelete([]);
    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [deleteReviews] = useMutation(DELETE_REVIEWS,{
        onCompleted:(d) => {

            const notify = new Notification();
            notify.setNotification(null,"A jsou fuč :)",true,false,client);

            OnCompleted(d.deleteReviews);
        },
        onError:(err) => {
            const helper = new Helper();
            const notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,client);   
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            const notify = new Notification();
            notify.setNotification(null,"Bez vybraných recenzí to nepůjde :)",false,true,client);
        }
    }

    const DeleteReviews = (action) => {

        if(action)
        {  
            deleteReviews({
                variables:{
                    reviewIDs:selectedItems.join(",")
                }
            })   
        }

        SetShowDeleteNotification(false);
    }

    return{
        selectedItems,
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteReviews,
        SelectItem
    }
}

export default UseDeleteReviews;