import React,{ Component } from 'react';
import TinyMCEFileManager from './TinyMCEFileManager';
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

const INITIAL_STATE = {
    
    showManager:false,
    tinyMCECallBack:"",
    tinyMCEFileType:""

}


class TinyMCEEditor extends Component {

    constructor(props){

        super(props);
        this.state = INITIAL_STATE;
        this.hideManager = this.hideManager.bind(this);
        
    }

    hideManager(){
        this.setState({
            showManager:false
        })
    }

    render(){

        var myServices = "";
        const {showManager,tinyMCECallBack,tinyMCEFileType} = this.state;
        const {allServices,allForms} = this.props;

        if(allServices && allServices.length > 0) myServices = "myservices ";
        if(allForms && allForms.length > 0) myServices += " allforms ";

        return(
            <div>

            <Editor
                name="description"
                value={this.props.initialValue}
                init={{
                    plugins: 'link image code paste emoticons media lists',
                    toolbar: 'undo redo bold italic alignleft aligncenter alignright formatselect ' + myServices + ' numlist bullist checklist link blockquote image media emoticons code',
                    paste_as_text: true,
                    language_url : '/langs/cs.js',
                    language: "cs",
                    height:1000,
                    image_advtab: true,
                    license_key: 'gpl',
                    promotion: false,
                    link_assume_external_targets: true,
                    file_picker_types: 'file image',
                    file_picker_callback: (callback, value, meta) => {
                        
                        if(meta.filetype == "image" || meta.filetype == "file"){
                            this.setState({showManager:true,tinyMCECallBack:callback, tinyMCEFileType:meta.filetype}) 
                        }

                    },
                    setup:(editor) => {

                        if(allServices && allServices.length > 0){

                            /* Menu items are recreated when the menu is closed and opened, so we need
                            a variable to store the toggle menu item state. */
                            var toggleState = false;

                            /* example, adding a toolbar menu button */
                            editor.ui.registry.addMenuButton('myservices', {
                                text: 'Služby',
                                fetch: function (callback) {
                                    var items = [];

                                    for(let i = 0; i < allServices.length; i++){

                                        items.push({
                                            type: 'menuitem',
                                            text: allServices[i].name,
                                            onAction: function () {
                                                editor.insertContent('<p>[---------s:' + allServices[i].serviceID + '@~@' + allServices[i].name + '---------]</p><p></p>');
                                            }
                                        })

                                    }

                                    callback(items);
                                }
                            });

                        }

                        if(allForms && allForms.length > 0){

                            /* example, adding a toolbar menu button */
                            editor.ui.registry.addMenuButton('allforms', {
                                text: 'Formuláře',
                                fetch: function (callback) {
                                    var items = [];

                                    for(let i = 0; i < allForms.length; i++){

                                        items.push({
                                            type: 'menuitem',
                                            text: allForms[i].name,
                                            onAction: function () {
                                                editor.insertContent('<p>[---------f:' + allForms[i].formID + '@~@' + allForms[i].name + '---------]</p><p></p>');
                                            }
                                        })

                                    }

                                    callback(items);
                                }
                            });

                        }
                    }
                        
                }}
                onEditorChange={(content) => this.props.onChange(content,"text")}
            />
            {showManager && 
                <TinyMCEFileManager callback={this.hideManager} tinyMCECallBack = {tinyMCECallBack} tinyMCEFileType={tinyMCEFileType} />
            }
            </div>
        )

    }

}

export default TinyMCEEditor;  