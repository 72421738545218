import gql from 'graphql-tag';

export const ADD_EDIT_REVIEW = gql`
    mutation AddEditReview($reviewID:ID,$active:Int,$photo:Upload,$langs:[ReviewLangsInput]){
        addEditReview(reviewID:$reviewID,active:$active,photo:$photo,langs:$langs){
            reviewID
            text
            photo
            active
        }
    }
`;

export const GET_REVIEW = gql`
    query ReviewWithLangs($reviewID: ID){
        reviewWithLangs(reviewID:$reviewID){
            reviewID
            photo
            active
            langs{
                lang
                text
                signature
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_REVIEWS = gql`
    query AllReviews($lang: String,$offset:Int, $limit: Int){
        allReviews(lang: $lang,offset:$offset,limit:$limit){   
            reviewID
            text
            photo
            active
        }
    }
`;

export const DELETE_REVIEWS = gql`
    mutation deleteReviews($reviewIDs:ID!) {
        deleteReviews(reviewIDs:$reviewIDs)
    }
`;