import { useQuery } from "react-apollo";
import { GET_REVIEW } from "../Queries/review";
import { useState } from "react";
import { SERVER_URL, REVIEW_IMAGE_URL } from "../../../Config";
import Helper from "../../../Library/helper";

const UseGetReview = (selectedReviewID,CallBack) => {

    const helper = new Helper();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);

    const {loading,error} = useQuery(GET_REVIEW,{
        variables:{
            reviewID:selectedReviewID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            var mainLang = "";
            for(let val of d.allLanguageMutations)
            {
                if(val.main == 1)
                {
                    mainLang = val.suffix;
                    break;
                }
            } 

            if(selectedReviewID != 0)
            {
                let langs = helper.CompleteLangsArray(d.reviewWithLangs.langs,d.allLanguageMutations,{
                    text:"",                    
                    signature:""
                });

                CallBack({
                    active:         d.reviewWithLangs.active,
                    photo:          null,
                    mainPhoto:      d.reviewWithLangs.photo ? SERVER_URL + "/" + REVIEW_IMAGE_URL + "/stredni_" + d.reviewWithLangs.photo : "",
                    langs:          langs,
                    mainLanguage:   mainLang
                });
            }
            else
            {
                let langs = helper.CompleteLangsArray([],d.allLanguageMutations,{
                    text:"",                    
                    signature:""
                });

                CallBack({
                    active:         1,
                    photo:          null,
                    mainPhoto:      "",
                    langs:          langs,
                    mainLanguage:   mainLang
                });
            }
   
            SetAllLanguageMutations(d.allLanguageMutations);
        }
    });

    return{
        loading,
        error,
        allLanguageMutations
    }

}

export default UseGetReview;