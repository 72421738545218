import React,{ Fragment, useEffect, useState } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


const ServiceElement = (props) => {

    const {element,showHelpers,cm,lastIndexes,lang} = props;

    const [service, SetService] = useState(null);

    useEffect(() => {
        if(element.serviceID)
            SetLocalService(element.serviceID,null);
        
    },[])

    const SetLocalService = (e,lastIndexes) => {

        var selected = false;
        for(let val of props.allServices)
        {
            if(val.serviceID == (e.target ? e.target.value : e))
            {
                SetService(val);
                selected = true;
                break;
            }
        }

        if(!selected)SetService(null);

        if(lastIndexes)
            cm.setElementData(e,lastIndexes,"service");
    }   

    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className="cm-headline">

            {showHelpers && element.showMenu &&
                <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                    <div className="form-inline">
                        
                        <div className="form-group mr-1">
                            <div className="item-container" onClick={() => cm.showSettings("service",lastIndexes)}>
                                <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                            </div>
                            {element.showSettings &&
                                <Fragment>
                                    <div className="settings">

                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Typ služby:</label>
                                                </div>
                                                <select value={element.serviceID} className="form-control" name="serviceID" onChange={(e) => SetLocalService(e,lastIndexes)}>
                                                    <option key={0} value={0}>---vyberte službu ---</option>
                                                    {props.allServices.map((item,index) => {
                                                        return(<option key={index + 1} value={item.serviceID}>{item.name}</option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>   
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Barva:</label>
                                                </div>
                                                <select value={element.color} className="form-control" name="color" onChange={(e) => cm.setElementData(e,lastIndexes,"service")}>
                                                    <option value=""> základní </option>
                                                    <option value="btn-green"> zelená </option>
                                                    <option value="btn-white"> bílá </option>
                                                </select>
                                            </div>
                                        </div>    
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Velikost:</label>
                                                </div>
                                                <select value={element.size} className="form-control" name="size" onChange={(e) => cm.setElementData(e,lastIndexes,"service")}>
                                                    <option value=""> malá </option>
                                                    <option value="bigger"> střední </option>
                                                    <option value="biggest"> velká </option>
                                                </select>
                                            </div>
                                        </div>                  
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Třída:</label>
                                                </div>
                                                <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"service")}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cm-closing-overlay" onClick={() => cm.showSettings("service",lastIndexes)}></div>
                                </Fragment>
                            }
                        </div>
                        {parentIndexes != "" &&
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                <img className="remove-icon"  src={remove} alt="Odstranit službu" title="Odstranit službu" />
                            </div>
                        </div>
                    </div>  
                </div>
            }

            <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"service",lastIndexes)}}>
                <div className={"d-flex justify-content-center"}>
                    
                    {service ?
                        <div>
                            
                            <div className="services">
                                <div className={"service-item"}>
                                    <button className={"btn btn-primary no-top-margin service " + element.size + " " + element.color + " " + element.className}>{service.name} </button>
                                </div>
                            </div>
                            
                        </div>
                    :
                        <div className = "cm-content-padding text-center">Vyberte některou ze služeb</div>
                    }
                                            
                </div>
            </div>
            {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
            {showHelpers && 
                <div className="cm-dragable-item" {...props.handle}>
                    <div className="item-container">
                        <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                    </div>
                </div>
            }

        </div>
    );
 
}

export default ServiceElement;