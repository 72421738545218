import Helper from "../Library/helper";
import { useEffect, useState } from "react";

const ChoosePhoto = ({photoUrl,SetPhotoData}) => {

    const [newPhotoUrl, SetNewPhotoUrl] = useState(photoUrl);

    useEffect(() => {

        if(photoUrl)SetNewPhotoUrl(photoUrl);
        
    },[photoUrl])

    const helper = new Helper();

    return(
        <>
            {newPhotoUrl ?
                <img className = "maxwidth-100" src={newPhotoUrl} />
            :null}
            <div className="form-group input-group">
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        name="file"
                        required
                        multiple
                        onChange={(e) => helper.OpenImage(e,(photo,dataURL) => {

                            SetNewPhotoUrl(dataURL);
                            SetPhotoData(photo);
                        })}
                    />
                    <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                </div>
            </div>
        </>
    )
}

export default ChoosePhoto;