const AddEditModalHeader = ({title,selectedLang,allLanguageMutations,SelectLanguage,Close}) => {

    return(
        <div className="modal-header d-flex align-items-center">
            <h4 className="modal-title">{title}</h4>
            
                {allLanguageMutations ?
                    <div className="ml-auto">
                        <select className="form-control" value={selectedLang} name="selectedLang" onChange={(e) => SelectLanguage(e)}>
                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                <option key={index} value={item.suffix}> {item.suffix} </option>
                            ))}
                        </select>
                    </div>
                :null}
                <button type="button" className={"close" + (allLanguageMutations ? " ml-3" : " ml-auto")} onClick={Close}>                                    
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                </button>
            
            
        </div>
    )
}

export default AddEditModalHeader;