let graphqlServerUrl              = "https://server.mujterapeut.cz/graphql";
let serverUrl                     = "https://server.mujterapeut.cz";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8906/graphql";
    serverUrl                     = "http://localhost:8906";
}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;

export const WEB_URL                         = "https://www.mujterapeut.cz";
export const TINYMCE_IMAGE_URL               = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                = "Public/Files/TinyMCE";
export const MARKETING_IMAGE_URL             = "Public/Images/Marketing";
export const ARTICLE_IMAGE_URL               = "Public/Images/Article";
export const CONTENT_MANAGER_IMAGE_URL       = "Public/Images/ContentManager";
export const CONTENT_MANAGER_VIDEO_URL       = "Public/Videos/ContentManager";
export const NEWSLETTER_IMAGE_PATH           = "Public/Images/Newsletter";
export const REVIEW_IMAGE_URL                = "Public/Images/Review";
export const SERVICE_IMAGE_URL                = "Public/Images/Service";
