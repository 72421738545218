import { useState,useContext } from "react";
import NextListItems from "../../../GlobalComponents/NextListItems";
import {REVIEW_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import ListItemOptions from "../../../GlobalComponents/ListItemOptions";
import YesOrNo from "../../../GlobalComponents/YesOrNo";
import AddEditReview from "./AddEditReview";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import {withApollo,useQuery} from 'react-apollo';
import { GET_REVIEWS } from "../Queries/review";
import UseDeleteReviews from "../Hooks/UseDeleteReviews";
import NoItems from "../../../GlobalComponents/NoItems";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import UpdateReviewList from "../Hooks/UseUpdateReviewList";
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const Reviews = ({client}) => {

    const listVariables = {
        lang:"cz",
        limit:30,
        offset:0,
    }

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showReviews)
        window.location.href="/";

    const [selectedReviewID,SetSelectedReviewID] = useState(null);

    const {data,loading,error,fetchMore} = useQuery(GET_REVIEWS,{
        variables:listVariables,
        fetchPolicy:"network-only"
    });

    const {
        UpdateAfterAddEdit,
        UpdateAfterDelete
    } = UpdateReviewList(listVariables,client);

    const {
        selectedItems,
        showDeleteNotification,
        DeleteReviews,
        ShowDeleteNotification,
        SelectItem
    } = UseDeleteReviews(client,(reviewIDs) => UpdateAfterDelete(reviewIDs));

    return(
        <div id="settings" className="whole-container" >	
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">

                        {error ?
                            <Error text = {error} />
                        :
                            <>           
                                <div className="card-header d-flex align-items-center">
                                    Seznam recenzí
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => SetSelectedReviewID(0)}>Přidat</button>
                                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => ShowDeleteNotification(true)}>Smazat označené</button>
                                </div>

                                <div className="card-body">
                                    
                                    {loading ?
                                        <Loading />  
                                    :
                                        <div>
                                            <div className="data-list">
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div className="static">Obrázek</div>
                                                        <div>Text</div>
                                                        <div className="static">Aktivní</div>
                                                        <div className="static text-right">Možnosti</div>
                                                    </div>
                                                </div>
                                                    
                                                {data.allReviews && data.allReviews.map((item,index) => {

                                                    var checked = false;
                                                    if(selectedItems.indexOf(item.reviewID) != -1)
                                                        checked = true;
                                                                                                                
                                                    return (
                                                        <div key={item.reviewID} className="data-list-item-content">
                                                            <div className="static">
                                                                {item.photo ?
                                                                    <img className="max-width-100" src={SERVER_URL + "/" + REVIEW_IMAGE_URL + "/mala_" + item.photo} />
                                                                :null}
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{__html:item.text}}></div>
                                                            <div className="static">
                                                                <YesOrNo value = {item.active} />
                                                            </div>
                                                            <div className="static text-right">
                                                                <ListItemOptions
                                                                    EditButtonFunction = {() => SetSelectedReviewID(item.reviewID)}
                                                                />
                                                                <input className="delete-checked" type="checkbox" name="deleteReviews[]" checked={checked} onChange = {(e) => SelectItem(item.reviewID)} />
                                                            </div>
                                                        </div>  
                                                    )
                                                })} 
                                                                        
                                            </div>

                                            {data.allReviews && data.allReviews.length == 0 ?
                                                <NoItems text={"Momentálně se zde nenachází žádné recenze."} />
                                            :
                                                <NextListItems 
                                                    buttonText = {"Načíst další recenze"} 
                                                    offset = {data.allReviews.length}
                                                    fetchMore = {fetchMore}
                                                    listObject = "allReviews"
                                                />
                                            }
                                        </div>
                                    }     
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            {selectedReviewID != null ?
                <AddEditReview 
                    SetSelectedReviewID = {SetSelectedReviewID}
                    selectedReviewID = {selectedReviewID}
                    UpdateAfterAddEdit = {UpdateAfterAddEdit}
                />    
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={'Opravdu chcete vymazat vybrané recenze ?'} 
                    callback={DeleteReviews} 
                />
            :null}

        </div>
    )
}

export default withApollo(Reviews);