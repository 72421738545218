import { useMutation } from "react-apollo";
import { ADD_EDIT_INVOICE } from "../Queries/invoice";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import Notification from '../../../Library/notification';
import Helper from "../../../Library/helper";
import moment from 'moment';
import { GET_CUSTOMERS } from "../../Customer/Queries/customer";
import { useState } from "react";

const UseAddEditInvoice = (selectedInvoiceID,client,OnCompleted) => {

    const notify = new Notification();
    const helper = new Helper();

    const [allCustomers,SetAllCustomers] = useState([]);
    const [searchText,SetSearchText] = useState("");
    
    const {formData, SetFormData, SetCompleteFormData,SetArrayObjValue} = UseFormHandle({
        invoiceID: 0,
        customerID: 0,
        serviceID: 0,
        domainID: 0,
        sendInvoiceByEmail:1,
        name: "",
        surname: "",
        email: "",
        tel: "", 
        street: "",
        city: "",
        zip: "",
        countryID: 60,
        company: "",
        ic: "",
        dic: "",
        dateAdd: moment().toDate(),
        dueDate: moment().add(14,"d").toDate(),
        items: []
    });

    const [AddEditInvoiceMutation,{error,loading}] = useMutation(ADD_EDIT_INVOICE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditInvoice);

            const notifyText = (selectedInvoiceID != 0 ? "Úspěšně uloženo" : "Nová faktura je na světě :)");

            notify.setNotification(null,notifyText,true,false,client);

        },
        onError:(err) => {
            err = helper.getApolloErrorText(err);
            notify.setNotification(null,err,false,true,client);
        }
    });

    const AddEditInvoice = () => {

        if(formData.domainID)
        {
            if(formData.items.length > 0)
            {
                if(formData.name != "" && formData.surname != "")
                {
                    if(formData.email != "" && helper.validateEmail(formData.email))
                    {
                        if(formData.dateAdd)
                        {
                            if(formData.dueDate)
                            {
                                for(let i in formData.items)
                                {
                                    formData.items[i].count = parseInt(formData.items[i].count);
                                    formData.items[i].amount = parseInt(formData.items[i].amount);
                                }

                                AddEditInvoiceMutation({
                                    variables:{
                                        data: {
                                            invoiceID: formData.invoiceID,
                                            customerID: formData.customerID,
                                            serviceID: formData.serviceID,
                                            domainID: formData.domainID,
                                            name: formData.name,
                                            surname: formData.surname,
                                            email: formData.email,
                                            tel: formData.tel,
                                            street: formData.street,
                                            city: formData.city,
                                            zip: formData.zip,
                                            countryID: formData.countryID,
                                            company: formData.company,
                                            ic: formData.ic,
                                            dic: formData.dic,
                                            dateAdd: moment(formData.dateAdd).format("YYYY-MM-DD"),
                                            dueDate: moment(formData.dueDate).format("YYYY-MM-DD"),
                                            sendInvoiceByEmail:parseInt(formData.sendInvoiceByEmail),
                                            items: formData.items
                                        }
                                    }
                                })
                            }
                            else
                                notify.setNotification(null,"Datum splatnosti je taky důležitý.",false,true,client);
                        }
                        else
                            notify.setNotification(null,"Datum vytvoření je taky důležitý.",false,true,client);
                    }
                    else
                        notify.setNotification(null,"Email není ve správném tvaru.",false,true,client);
                }
                else
                    notify.setNotification(null,"Je potřeba vyplnit jméno a příjmení.",false,true,client);
            }
            else
                notify.setNotification(null,"Faktura bez položek není faktura :)",false,true,client);
        }
        else
            notify.setNotification(null,"Je potřeba vybrat doménu.",false,true,client);
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const RemoveItem = (index) => {
        var newArr = [...formData.items];
        newArr.splice(index,1);

        SetFormData("items",newArr);
    }

    const AddItem = () => {

        var newArr = [...formData.items];
        newArr.push({
            text:"",
            amount:0,
            count:1
        })

        SetFormData("items",newArr);
    }

    const SetCustomer = (customer) => {

        SetCompleteFormData({
            customerID:customer.customerID,
            name:customer.name,
            surname:customer.surname,
            email:customer.email,
            tel:customer.tel,
            street:customer.street,
            city:customer.city,
            zip:customer.zip,
            countryID:customer.countryID,
            company:customer.company,
            ic:customer.ic,
            dic:customer.dic
        })

        SetAllCustomers([]);
        SetSearchText("");
        
    }

    const GetCustomers = async(text) => {

        if(text != "")
        {
            var data = await client.query({
                query:GET_CUSTOMERS,
                variables:{
                    offset:0, 
                    limit:100000,
                    text:text
                },
                fetchPolicy: 'network-only'
            });

            if(data)
            {
                SetAllCustomers(data.data.allCustomers);
                SetSearchText(text);
            }
        }
        else
        {
            SetAllCustomers([]);
            SetSearchText("");
        }
    }

    return {
        error: error,
        loading: loading,
        formData,
        allCustomers,
        searchText,
        AddEditInvoice,
        SetFormData,
        InitFormData,
        GetCustomers,
        SetCustomer,
        AddItem,
        RemoveItem,
        SetArrayObjValue
    }

}

export default UseAddEditInvoice;