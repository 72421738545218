import edit from '../Public/Images/edit.svg';

const ListItemOptions = ({EditButtonFunction}) => {

    return(
        <>
            {EditButtonFunction ?
                <img onClick={EditButtonFunction} className="edit-icon" src={edit} /> 
            :null}
        </>
    )

}

export default ListItemOptions;