import { useState } from "react";

const UseSelectItemToDelete = (items) => {

    const [selectedItems, SetSeletctedItems] = useState(items);

    const SetItem = (item) => {

        var arr   = [...selectedItems];
        var check = false;

        var index = arr.indexOf(item);
        if(index != -1)
        {
            check = true;
            arr.splice(index,1);
        }

        if(!check)
            arr.push(item)

        SetSeletctedItems(arr);
    }
    

    return[selectedItems,SetItem]
}

export default UseSelectItemToDelete;