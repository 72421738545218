import React,{ Component,Fragment } from 'react';
import HeadlineElement from './HeadlineElement';
import ColElement from './DragColumnsElement';
import AddElement from './AddElement';
import ParagraphElement from './ParagraphElement';
import ImageElement from './ImageElement';
import ButtonElement from './ButtonElement';
import VideoElement from './VideoElement';
import ArticlesElement from './ArticlesElement';
import NewsletterElement from './NewsletterElement'; 
import HtmlElement from './HtmlElement';
import FaqElement from './FaqElement';
import FormElement from './FormElement';
import ServiceElement from './ServiceElement';
import AlertElement from './AlertElement';
import ReviewElement from './ReviewElement';
import TherapistElement from './TherapistElement';
import remove from '../../../Public/Images/remove.svg';
import parent from '../../../Public/Images/parent.svg';
import settings from '../../../Public/Images/settings.svg';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


class DragColumnElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {lastIndexes,cm,showHelpers,iframeNode,fullWidth,lang,item,index,colsLength} = this.props;

        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        var positions = [];
        for(let i = 1; i <= colsLength; i++)
            positions.push(i);

        return (
            <>

                {showHelpers && item.showMenu &&
                    <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <AddElement columnType="drag" lastIndexes = {lastIndexes + "-" + index} element={item} cm={cm}  />
                            </div>
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("drag_column",lastIndexes + "-" + index)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {item.showSettings &&
                                    <Fragment>
                                        <div className="settings">

                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Pozice:</label>
                                                    </div>
                                                    <select className="form-control" name="position" value={index + 1} onChange={(e) => cm.changeDragColPosition(e,lastIndexes,index + 1)}>
                                                        {positions.map((item,index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Zarovnání:</label>
                                                    </div>
                                                    <select value={item.contentAlign} className="form-control" name="contentAlign" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="start"> nahoře </option>
                                                        <option value="center"> centrovaně </option>
                                                        <option value="end"> dole </option>
                                                    </select>
                                                </div>
                                            </div>
                                    
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Odsazení zhora:</label>
                                                    </div>
                                                    <input type="number" value={item.paddingTop} className="form-control" name="paddingTop" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}  />
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Odsazení zprava:</label>
                                                    </div>
                                                    <input type="number" value={item.paddingRight} className="form-control" name="paddingRight" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}  />
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Odsazení zespod:</label>
                                                    </div>
                                                    <input type="number" value={item.paddingBottom} className="form-control" name="paddingBottom" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}  />
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Odsazení zleva:</label>
                                                    </div>
                                                    <input type="number" value={item.paddingLeft} className="form-control" name="paddingLeft" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}  />
                                                </div>
                                            </div>

                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Šířka sloupce v rámci šířky displaye</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">větší než 1200</label>
                                                    </div>
                                                    <select className="form-control" value={item.xlCols} name="xlCols" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="100">100%</option>
                                                        <option value="75">75%</option>
                                                        <option value="66">66%</option>
                                                        <option value="60">60%</option>
                                                        <option value="50">50%</option>
                                                        <option value="40">40%</option>
                                                        <option value="33">33%</option>
                                                        <option value="25">25%</option>
                                                        <option value="16">16%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">992 - 1199</label>
                                                    </div>
                                                    <select className="form-control" value={item.lgCols} name="lgCols" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="100">100%</option>
                                                        <option value="75">75%</option>
                                                        <option value="66">66%</option>
                                                        <option value="60">60%</option>
                                                        <option value="50">50%</option>
                                                        <option value="40">40%</option>
                                                        <option value="33">33%</option>
                                                        <option value="25">25%</option>
                                                        <option value="16">16%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">768 - 991</label>
                                                    </div>
                                                    <select className="form-control" value={item.mdCols} name="mdCols" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="100">100%</option>
                                                        <option value="75">75%</option>
                                                        <option value="66">66%</option>
                                                        <option value="60">60%</option>
                                                        <option value="50">50%</option>
                                                        <option value="40">40%</option>
                                                        <option value="33">33%</option>
                                                        <option value="25">25%</option>
                                                        <option value="16">16%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">577 - 767</label>
                                                    </div>
                                                    <select className="form-control" value={item.smCols} name="smCols" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="100">100%</option>
                                                        <option value="75">75%</option>
                                                        <option value="66">66%</option>
                                                        <option value="60">60%</option>
                                                        <option value="50">50%</option>
                                                        <option value="40">40%</option>
                                                        <option value="33">33%</option>
                                                        <option value="25">25%</option>
                                                        <option value="16">16%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">menší než 576</label>
                                                    </div>
                                                    <select className="form-control" value={item.xsCols} name="xsCols" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}>
                                                        <option value="100">100%</option>
                                                        <option value="75">75%</option>
                                                        <option value="66">66%</option>
                                                        <option value="60">60%</option>
                                                        <option value="50">50%</option>
                                                        <option value="40">40%</option>
                                                        <option value="33">33%</option>
                                                        <option value="25">25%</option>
                                                        <option value="16">16%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={item.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"drag_column")}  />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("drag_column",lastIndexes + "-" + index)}></div>
                                    </Fragment>
                                }
                            </div>
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,"drag_columns",lastIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                            {colsLength > 1 ?
                                <div className="form-group">
                                    <div className="item-container" onClick={() => cm.removeDragColumn(lastIndexes,index)}>
                                        <img className="remove-icon" src={remove} alt="Odstranit sloupec" title="Odstranit sloupec" />
                                    </div>
                                </div>
                            :null}
                        </div>
                    </div>
                }
                <div className={item.className + " " + (item.contentAlign != "start"  ? "cm-col-content h-100 d-flex flex-column justify-content-" + item.contentAlign : "cm-col-content")} onClick={(e) => cm.selectElement(e,"drag_column",lastIndexes + "-" + index)} style={(item.paddingTop != 0 || item.paddingRight != 0 || item.paddingBottom != 0 || item.paddingLeft != 0 ? {paddingTop:item.paddingTop+"px",paddingRight:item.paddingRight+"px",paddingBottom:item.paddingBottom+"px",paddingLeft:item.paddingLeft+"px"} : {})}>
                
                    <DragDropContext onDragEnd={(result) => cm.onDragEnd(result,lastIndexes + "-" + index)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    
                                >
                                    {item.elements && item.elements.map((elmItem,elmIndex) => {

                                        return(
                                            <Draggable key={elmIndex} draggableId={elmIndex+1} index={elmIndex}>
                                                {(provided, snapshot) => (
                                                <div  
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    
                                                >
                                                    
                                                    {elmItem.headline &&
                                                        <HeadlineElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} cm={cm} element = {elmItem.headline} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.drag_columns &&
                                                        <ColElement allReservationPersons = {this.props.allReservationPersons} allActiveReviews = {this.state.allActiveReviews} allForms={this.props.allForms} allServices={this.props.allServices} handle = {provided.dragHandleProps} lang={lang} fullWidth = {fullWidth} iframeNode = {iframeNode} cm={cm} element = {elmItem.drag_columns} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.paragraph &&
                                                        <ParagraphElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} cm={cm} element = {elmItem.paragraph} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.image &&
                                                        <ImageElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.image} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.button &&
                                                        <ButtonElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.button} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.video &&
                                                        <VideoElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.video} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.articles &&
                                                        <ArticlesElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.articles} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.newsletter &&
                                                        <NewsletterElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.newsletter} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.faq &&
                                                        <FaqElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.faq} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.form &&
                                                        <FormElement allForms={this.props.allForms} handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.form} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.service &&
                                                        <ServiceElement allServices={this.props.allServices} handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.service} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.html &&
                                                        <HtmlElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.html} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.alert &&
                                                        <AlertElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.alert} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.review &&
                                                        <ReviewElement allActiveReviews = {this.state.allActiveReviews} handle = {provided.dragHandleProps} lang={lang} element = {elmItem.review} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }
                                                    {elmItem.therapist &&
                                                        <TherapistElement allReservationPersons = {this.props.allReservationPersons} handle = {provided.dragHandleProps} lang={lang} element = {elmItem.therapist} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                    }

                                                </div>
                                                )}
                                            </Draggable>
                                        )
                                        
                                    })}
                                    {provided.placeholder}
                                                                    
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                {showHelpers && <div className={"cm-col-border" + (item.showMenu ? " cm-selected" : "")} onClick={(e) => cm.selectElement(e,"drag_column",lastIndexes + "-" + index)} ></div>}
                
                
            </>
        );

    }
}

export default DragColumnElement;