import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';
import ContentEditable from 'react-contenteditable'


class AlertElement extends Component{

    constructor(props){
        super(props);
    }

    onPaste(e,type){
        e.preventDefault(); 
        const text = e.clipboardData.getData('text/plain'); 
        if(text != "")this.props.cm.setElementData(type,this.props.lastIndexes,"alert",text,null,true);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("alert",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Typ:</label>
                                                    </div>
                                                    <select value={element.type} className="form-control" name="type" onChange={(e) => cm.setElementData(e,lastIndexes,"alert")}>
                                                        <option value="success"> Úspěch </option>
                                                        <option value="warning"> Upozornění </option>
                                                        <option value="danger"> Je to blbý </option>
                                                    </select>
                                                </div>
                                            </div>
                                                                                       
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"alert")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("alert",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit upozornění" title="Odstranit upozornění" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"alert",lastIndexes)}}>

                    <ContentEditable onClick={(e) => e.preventDefault()} tagName={"div"} className={"alert alert-" + element.type + " " + element.className} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"alert",null,null,true)}} />
                    
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default AlertElement;