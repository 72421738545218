import { GET_REVIEWS } from "../Queries/review";

const UpdateReviewList = (variables,client) => {

    const UpdateAfterAddEdit = (newData,type) => {

        const { allReviews } = client.readQuery({ query: GET_REVIEWS, variables:variables });  
        if(type == "add"){

            const arr = [newData,...allReviews]   ;
            client.writeQuery({ 
                query:GET_REVIEWS,
                variables:variables,
                data:{
                    allReviews: arr
                } 
            });

        }else{

            var arr = [...allReviews];
            arr.forEach((item,index) => {
                
                if(item.reviewID == newData.reviewID)
                {                 
                    arr[index] = {...arr[index],
                        reviewID:newData.reviewID,
                        active:newData.active,
                        text:newData.text
                    }
                    if(newData.photo)
                        arr[index] = {...arr[index],photo:newData.photo}
                }

            });

            client.writeQuery({ 
                query:GET_REVIEWS,
                variables:variables,
                data:{
                    allReviews: arr
                } 
            });
        }
    }

    const UpdateAfterDelete = (reviewIDs) => {

        var resp = reviewIDs.split(",");

        const { allReviews } = client.readQuery({ query: GET_REVIEWS ,variables});
        var arr = [...allReviews];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.reviewID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        client.writeQuery({ 
            query:GET_REVIEWS,
            variables,
            data:{
                allReviews: [...arr]
            } 
        });
        
    }

    return {
        UpdateAfterAddEdit,
        UpdateAfterDelete
    }

}

export default UpdateReviewList;