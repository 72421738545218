import { useMutation } from "react-apollo";
import { useState } from "react";
import { BLOCK_DAY } from "../Queries/reservations";
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';

const UseBlockDay = (client,reservationPersonID,OnSuccess) => {

    const [showNotify, SetShowNotify] = useState(false);
    const [selectedDate, SetSelectedDate] = useState("");

    const [BlDay,{error,loading}] = useMutation(BLOCK_DAY,{
        onCompleted:(d) => {

            OnSuccess(d.reservationBlockDay);
            
            const notify = new Notification();
            notify.setNotification(null,"A už se tam nikdo nedostane.",true,false,client);

        },
        onError:(err) => {
            const helper = new Helper();
            const notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,client);   
        }
    });

    const ShowNotification = (value) => {
        SetSelectedDate(value);
        SetShowNotify(true);
    }

    const BlockDay = (act) => {

        if(act)
        {
            BlDay({
                variables:{
                    date: selectedDate,
                    reservationPersonID
                }
            })
        }
        SetSelectedDate("");
        SetShowNotify(false);
    }

    const BlockDayWithoutNotify = (date) => {
 
        BlDay({
            variables:{
                date: date,
                reservationPersonID
            }
        })
        
    }

    
    return{
        loading,
        error,
        showNotify,
        BlockDay,
        ShowNotification,
        BlockDayWithoutNotify
    }

}

export default UseBlockDay;