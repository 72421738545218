import React,{ Fragment, useEffect, useState } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';
import { SERVER_URL,REVIEW_IMAGE_URL } from '../../../Config';
import AddReview from './AddReview';


const ReviewElement = (props) => {

    const {element,showHelpers,cm,lastIndexes,allActiveReviews} = props;

    const [selectedReviews,SetSelectedReviews] = useState([]);
    const [showAddReviews, SetShowAddReviews] = useState(false);

    useEffect(() => {

        if(element.reviewIDs)
        {
            var newReviews = [];

            for(let i = 0; i < element.reviewIDs.length; i++)
            {
                var checkID = null;
                for(let val of allActiveReviews)
                {
                    if(val.reviewID == element.reviewIDs[i])
                        checkID = element.reviewIDs[i];
                }
        
                if(checkID)
                    newReviews.push(checkID);
        
            }

            SetSelectedReviews(newReviews);
        }

    },[element.reviewIDs])

    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    const SelectReview = (reviewID) => {

        var arr = [...selectedReviews];
        var check = false;
        
        const index = arr.indexOf(reviewID);
        if(index != -1)
        {
            check = true;
            arr.splice(index,1);
        }

        if(!check)
            arr.unshift(reviewID);

        cm.setElementData("reviewIDs",lastIndexes,"review",arr);
        SetSelectedReviews(arr);
    }

    const RemoveReview = (index) => {
        var arr = [...selectedReviews];
        arr.splice(index,1);

        cm.setElementData("reviewIDs",lastIndexes,"review",arr);
        SetSelectedReviews(arr);
    }

    const ChangePosition = (oldPosition,newPosition) => {

        var arr = [...selectedReviews];
        const review = arr[oldPosition - 1];

        arr = [...arr.slice(0,oldPosition - 1),...arr.slice(oldPosition)];
        arr = [...arr.slice(0,newPosition - 1),review,...arr.slice(newPosition-1)];

        cm.setElementData("reviewIDs",lastIndexes,"review",arr);
        SetSelectedReviews(arr);
    }


    var positions = [];
    for(let i = 1; i <= selectedReviews.length; i++)
        positions.push(i);
        
    return (
        <div className="cm-headline">

            {showHelpers && element.showMenu &&
                <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                    <div className="form-inline">
                        
                        <div className="form-group mr-1">
                            <div className="form-group mr-1">
                                <button onClick={() => SetShowAddReviews(true)}>Přidat recenzce</button>
                            </div>
                            <div className="item-container" onClick={() => cm.showSettings("review",lastIndexes)}>
                                <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                            </div>
                            {element.showSettings &&
                                <Fragment>
                                    <div className="settings">
            
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Třída:</label>
                                                </div>
                                                <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"review")}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cm-closing-overlay" onClick={() => cm.showSettings("review",lastIndexes)}></div>
                                </Fragment>
                            }
                        </div>
                        {parentIndexes != "" &&
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                <img className="remove-icon"  src={remove} alt="Odstranit službu" title="Odstranit službu" />
                            </div>
                        </div>
                    </div>  
                </div>
            }

            <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"review",lastIndexes)}}>
                <div className={""}>
                    
                    
                    <div className = "cm-content-padding text-center">Vyberte recenze, které zde chcete zobrazit</div>
                    <div className="review-container">
                        {selectedReviews.map((item,index) => {

                            var review = null;
                            for(let val of allActiveReviews)
                            {
                                if(val.reviewID == item)
                                {
                                    review = val;
                                }
                            }

                            if(review)
                            {
                                return(
                                    
                                    <div className="review" key={index}>
                                        <div className="cm-menu" style={{right:"20px"}}>
                                            <div className="form-inline">
                                                <div className="form-group mr-2">
                                                    <select className="form-control" name="position" value={index + 1} onChange={(e) => ChangePosition(index + 1,e.target.value)}>
                                                        {positions.map((pos,ind) => (
                                                            <option key={ind} value={pos}>{pos}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <div className="item-container" onClick={() => RemoveReview(index)}>
                                                        <img className="remove-icon no-margin"  src={remove} alt="Odstranit recenzi" title="Odstranit recenzi" />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="review-checkbox"></div>
                                        {review.photo ?
                                            <div>
                                                <img src={SERVER_URL + "/"+ REVIEW_IMAGE_URL + "/mala_" + review.photo} />
                                            </div>
                                        :null}
                                        <div className="review-text-content">
                                            <div className="review-text" dangerouslySetInnerHTML={{__html:review.text}}></div>
                                            <div className="review-signature" dangerouslySetInnerHTML={{__html:review.signature}}></div>
                                        </div>
                                        
                                    </div>
                                )
                            }
                            else 
                                return null;
                        })}
                    </div>
                    
                                            
                </div>
            </div>
            {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
            {showHelpers && 
                <div className="cm-dragable-item" {...props.handle}>
                    <div className="item-container">
                        <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                    </div>
                </div>
            }

            <AddReview
                isOpen = {showAddReviews}
                Close  = {() => SetShowAddReviews(false)}
                allActiveReviews = {allActiveReviews}
                selectedReviews = {selectedReviews}
                SelectReview = {SelectReview}
            />

        </div>
    );
 
}

export default ReviewElement;