import Notification from '../../../Library/notification';
import {GET_NEWSLETTER_DATA} from '../Queries/newsletter';
import moment from 'moment';

class Newsletter {

    constructor(props){
        this.props = props;
    }
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    updateAfterDelete(cache, response){

        var resp = response.data.deleteNewsletters.split(",");

        const { allPlannedNewsletters,allLanguageMutations } = cache.readQuery({ query: GET_NEWSLETTER_DATA});
        var arr = [...allPlannedNewsletters];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.newsletterID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_NEWSLETTER_DATA,
            data:{
                allPlannedNewsletters: [...arr],
                allLanguageMutations
            } 
        });

    }

    openImage(e,formData,setFormData,setMainPhoto){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            setFormData({...formData,photo: photo});
            setMainPhoto(dataURL);

        };
        reader.readAsDataURL(photo);
    }

    formHandleEditor(content,formData,setFormData){
        
        setFormData({...formData,text: content});
    }

    sendEmail(sendNewsletter,formData,isTest){

        let notify = new Notification();

        if(formData.subject != ""){

            if(formData.preheader != ""){

                if(formData.text != ""){

                    if(formData.testEmail != "" && isTest == true || !isTest){

                        sendNewsletter({
                            variables:{
                                newsletterID:  formData.newsletterID,
                                subject:       formData.subject,
                                preheader:     formData.preheader,
                                photo:         formData.photo,
                                uploadedPhoto: formData.uploadedPhoto,
                                text:          formData.text,
                                testEmail:     formData.testEmail,
                                isTest:        !!isTest,
                                lang:          formData.lang,
                                isPlanned:     parseInt(formData.isPlanned),
                                campaignID:    formData.campaignID,
                                plannedDate:   moment(formData.plannedDate).format("YYYY-MM-DD"),
                            }
                        })

                    }else{
            
                        notify.setNotification(null,'Nevyplnili jste testovací email.',false,true,this.props.client);
                               
                    }

                }else{
        
                    notify.setNotification(null,'Nevyplnili jste text.',false,true,this.props.client);
                           
                }

            }else{
    
                notify.setNotification(null,'Nevyplnili jste preheader.',false,true,this.props.client);
                       
            }

        }else{

            notify.setNotification(null,'Nevyplnili jste předmět.',false,true,this.props.client);
                   
        }

    }

    getLayoutContent(layoutID){

        var layout;

        switch(layoutID){
            case 1: layout = this.promoLayout();break;
            default: layout = this.promoLayout();break;
        }

        return layout;

    }

    promoLayout(){

        return '<table style="border-collapse:collapse;min-width:100%">' +
                '<tbody>'+
                    '<tr>'+
                        '<td>'+
                            '<table style="border-collapse:collapse;min-width:100%">'+
                                '<tbody>'+
                                    '<tr>'+
                                        '<td colspan="2"><p style="font-size:24px;font-weight:bold;text-align:center;text-transform:uppercase;color:#e67e23;margin:20px 0 10px 0">Astrální cestování</p></td>'+
                                    '</tr>'+
                                    '<tr>'+
                                        '<td style="width:290px;padding:10px;vertical-align:top;">'+
                                            '<img style="width:100%;" src="https://laskyplnysvet.cz:8898/Public/Images/TinyMCE/velka_sidris_ac.jpg" />'+
                                        '</td>'+
                                        '<td style="width:290px;padding:10px;">'+
                                            '<p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Mauris elementum mauris vitae tortor.</p>'+
                                            '<div style="text-align:center;margin-bottom:15px;"><a style="background-color: #f9f9f9;font-size: 12px;padding:15px 25px; color:#4f3b30;font-weight:bold;text-transform:uppercase;text-decoration:none;border-radius:50px; border-bottom: 2px solid #ccc;display: inline-block;" href="https://www.astralnicestovani.cz">⭐️ Kam to bude ? ⭐️</a></div>'+
                                        '</td>'+
                                    '</tr>' +
                                    '<tr>'+
                                        '<td colspan="2"><p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Nullam rhoncus aliquam metus. Phasellus rhoncus. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. In dapibus augue non sapien. Aliquam id dolor.</p></td>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td>'+
                            '<table style="border-top:1px solid #ddd;border-collapse:collapse;min-width:100%;">'+
                                '<tbody>'+
                                    '<tr>'+
                                        '<td colspan="2"><p style="font-size:24px;font-weight:bold;text-align:center;text-transform:uppercase;color:#e67e23;margin:35px 0 10px 0">Láskyplný svět</p></td>'+
                                    '</tr>'+
                                    '<tr>'+
                                        '<td style="width:290px;padding:10px;vertical-align:top;">'+
                                            '<img style="width:100%;" src="https://laskyplnysvet.cz:8898/Public/Images/TinyMCE/velka_laskyplnysvet_logo.jpg" />'+
                                        '</td>'+
                                        '<td style="width:290px;padding:10px;">'+
                                            '<p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Mauris elementum mauris vitae tortor.</p>'+
                                            '<div style="text-align:center;margin-bottom:15px;"><a style="background-color: #f9f9f9;font-size: 12px;padding:15px 25px; color:#4f3b30;font-weight:bold;text-transform:uppercase;text-decoration:none;border-radius:50px; border-bottom: 2px solid #ccc;display: inline-block;" href="https://www.astralnicestovani.cz">⭐️ Kam to bude ? ⭐️</a></div>'+
                                        '</td>'+
                                    '</tr>' +
                                    '<tr>'+
                                        '<td colspan="2"><p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Nullam rhoncus aliquam metus. Phasellus rhoncus. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. In dapibus augue non sapien. Aliquam id dolor.</p></td>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td>'+
                            '<table style="border-top:1px solid #ddd;border-collapse:collapse;min-width:100%;">'+
                                '<tbody>'+
                                    '<tr>'+
                                        '<td colspan="2"><p style="font-size:24px;font-weight:bold;text-align:center;text-transform:uppercase;color:#e67e23;margin:35px 0 10px 0">Můj terapeut</p></td>'+
                                    '</tr>'+
                                    '<tr>'+
                                        '<td style="width:290px;padding:10px;vertical-align:top;">'+
                                            '<img style="width:100%;" src="https://laskyplnysvet.cz:8898/Public/Images/TinyMCE/velka_snimek-obrazovky-2022-07-29-v-14-23-54.png" />'+
                                        '</td>'+
                                        '<td style="width:290px;padding:10px;">'+
                                            '<p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Mauris elementum mauris vitae tortor.</p>'+
                                            '<div style="text-align:center;margin-bottom:15px;"><a style="background-color: #f9f9f9;font-size: 12px;padding:15px 25px; color:#4f3b30;font-weight:bold;text-transform:uppercase;text-decoration:none;border-radius:50px; border-bottom: 2px solid #ccc;display: inline-block;" href="https://www.astralnicestovani.cz">⭐️ Kam to bude ? ⭐️</a></div>'+
                                        '</td>'+
                                    '</tr>' +
                                    '<tr>'+
                                        '<td colspan="2"><p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Nullam rhoncus aliquam metus. Phasellus rhoncus. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. In dapibus augue non sapien. Aliquam id dolor.</p></td>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td>'+
                            '<table style="border-top:1px solid #ddd;border-collapse:collapse;min-width:100%;">'+
                                '<tbody>'+
                                    '<tr>'+
                                        '<td colspan="2"><p style="font-size:24px;font-weight:bold;text-align:center;text-transform:uppercase;color:#e67e23;margin:35px 0 10px 0">Astrální akademie</p></td>'+
                                    '</tr>'+
                                    '<tr>'+
                                        '<td style="width:290px;padding:10px;vertical-align:top;">'+
                                            '<img style="width:100%;" src="https://laskyplnysvet.cz:8898/Public/Images/TinyMCE/velka_snimek-obrazovky-2022-07-29-v-15-04-38.png" />'+
                                        '</td>'+
                                        '<td style="width:290px;padding:10px;">'+
                                            '<p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Mauris elementum mauris vitae tortor.</p>'+
                                            '<div style="text-align:center;margin-bottom:15px;"><a style="background-color: #f9f9f9;font-size: 12px;padding:15px 25px; color:#4f3b30;font-weight:bold;text-transform:uppercase;text-decoration:none;border-radius:50px; border-bottom: 2px solid #ccc;display: inline-block;" href="https://www.astralnicestovani.cz">⭐️ Kam to bude ? ⭐️</a></div>'+
                                        '</td>'+
                                    '</tr>' +
                                    '<tr>'+
                                        '<td colspan="2"><p style="color:#333333;line-height:25px;margin:0 0 25px 0;font-size:14px;">Nullam rhoncus aliquam metus. Phasellus rhoncus. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. In dapibus augue non sapien. Aliquam id dolor.</p></td>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</td>'+
                    '</tr>'+
                '</tbody>'+
               '</table>'

    }

}

export default Newsletter;