/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_FORM} from '../Queries/index.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import FormLib from '../Library/form';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';

const INITIAL_STATE = {
    err:"",
    formData:{
        formID:0,
        thanksPageTypeID:1,
        thanksLinkID:0,
        langs:[],
        selectedLang:'cz',
        selectedCurrency:'Kč',
    },
    allLinks:[],
    allLanguageMutations:[]
}

class AddEditForm extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.formLib = new FormLib(this); 
    }

    componentDidMount(){
        this.formLib.getData();
    }    

    render(){

        var {err}               = this.state;
        var {selectedFormID,listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_FORM}
                errorPolicy = "all"
                update = {async (cache, response) => {
                    this.formLib.updateList(cache,response,selectedFormID,listVariables);

                    let notify = new Notification();

                    if(selectedFormID && selectedFormID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditForm,{loading, error}) => {

                    const {selectedLang,thanksLinkID,thanksPageTypeID} = this.state.formData;
                    const {allLanguageMutations,allLinks} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedFormID && selectedFormID != 0 ? "Úprava služby" : "Přidání služby")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.formLib.formLangObjectHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.languageID}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">                
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">Název formuláře ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="name" value={this.formLib.getLangValue("name")} onChange={(e) => this.formLib.formLangHandle(e)} />
                                                    </div>
                                                </div>  
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">SengridID ({selectedLang})</label>
                                                    <div className="input-group">
                                                        <input className="form-control" type="text" name="sengridID" value={this.formLib.getLangValue("sengridID")} onChange={(e) => this.formLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">SengridID 2 ({selectedLang})</label>
                                                    <div className="input-group">
                                                        <input className="form-control" type="text" name="sengridID2" value={this.formLib.getLangValue("sengridID2")} onChange={(e) => this.formLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label className="input-label">Děkovací stránka ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="thanksPageTypeID" id="thanksPageTypeID1" value="1" checked={(thanksPageTypeID == 1 ?  true : false)} onChange={(e) => this.formLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="thanksPageTypeID1">
                                                                Bez děkovací stránky
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="thanksPageTypeID" id="thanksPageTypeID2" value="2" checked={(thanksPageTypeID == 2 ?  true : false)} onChange={(e) => this.formLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="thanksPageTypeID2">
                                                                Vyskakovcí okno s textem
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="thanksPageTypeID" id="thanksPageTypeID3" value="3" checked={(thanksPageTypeID == 3 ?  true : false)} onChange={(e) => this.formLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="thanksPageTypeID3">
                                                                Konkrétní odkaz
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                    {thanksPageTypeID == 2 ?
                                                        <div className="form-group">
                                                            <TinyMCEBasic 
                                                                name="text"
                                                                value={this.formLib.getLangValue("text")}
                                                                OnEditorChange={(content) => this.formLib.formLangHandleEditor(content,"text")}
                                                            />
                                                            
                                                        </div>
                                                    :null}

                                                    {thanksPageTypeID == 3 ?
                                                        <div className="form-group">
                                                            <select value={thanksLinkID} name="thanksLinkID" className="form-control" onChange={(e) => this.formLib.formHandle(e)}>
                                                                <option key={0} value={0}>--- vyberte odkaz ---</option>
                                                                {allLinks.map((item,index) => {
                                                                    return(
                                                                        <option key={item.linkID} value={item.linkID}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    :null}

                                                    
                                                </div>
                                                
                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.formLib.addForm(addEditForm)}>{(selectedFormID && selectedFormID != 0 ? "Upravit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditForm);