/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
 import gql from 'graphql-tag';

 /**
  * vložení kategorie
  */
 export const ADD_EDIT_CAMPAIGN = gql`
     mutation AddEditCampaign($campaignID:ID,$name:String,$active:Int,$isForMainProduct:Int,$isForNewsletter:Int,$services:[ID],$forms:[ID]){
         addEditCampaign(campaignID:$campaignID,name:$name,active:$active,isForMainProduct:$isForMainProduct,isForNewsletter:$isForNewsletter,services:$services,forms:$forms){
             campaignID
             name
             active
         }
     }
 `;
  
 /**
  *  Data pro jednu kategorii
  */
 
 export const GET_CAMPAIGN = gql`
     query CampaignWithLangs($campaignID: ID!,){
        campaign(campaignID:$campaignID){
            campaignID
            name
            active 
            isForMainProduct  
            isForNewsletter 
            services{
                serviceID
            }
            forms{
                formID
            }
        }
        allForms(lang: "cz"){
            formID
            name
        }
        allServices(lang: "cz"){
            serviceID
            name
        }
     }
 `;
 
 /**
  *  Seznam veškerých kategorií
  */
 export const GET_CAMPAIGNS = gql`
     query AllCampaigns{
        allCampaigns{
            campaignID
            name
            active
            contactCount
            activeContactCount
            uniqueContactCount
            contactCountWithoutUnsubscribed
        }
     }
 `;
 
 /**
  * Smazání kategorie.
  */
 export const DELETE_CAMPAIGNS = gql`
     mutation deleteCampaigns($campaignIDs:ID!) {
         deleteCampaigns(campaignIDs:$campaignIDs)
     }
 `;
 
 /**
  * vyhledání služeb
  */
 export const SEACRH_CAMPAIGNS = gql`
 
     query SearchCampaigns($text: String!,$lang:String){
         searchCampaigns(text:$text,lang:$lang){
             name
             campaignID
         }
 
     }
 
 `;

 export const COPY_CAMPAIGN = gql`
    mutation copyCampaign($campaignID:ID) {
        copyCampaign(campaignID:$campaignID)
    }
`;